.content__sidebar
  width: $width-sidebar
  height: 100vh
  background: linear-gradient(90deg, $color-primary 60px, white 60px)
  transition: $sidebar-transition
  top: 0
  left: -$sidebar-mobile-width
  @media screen and (min-width: 426px)
    left: -$width-sidebar
  padding-top: $topbar-height
  z-index: 3

  &:before
    content: ""
    display: block
    background-color: $color-primary
    top: 0
    bottom: 0
    width: $sidebar-red-width
    position: absolute
    z-index: -1
    left: 0

  .menu-is-open &
    left: 0
    width: $sidebar-mobile-width
    overflow: auto
    @media screen and (min-width: 426px)
      width: $width-sidebar


  .logo-item
    margin-left: $sidebar-red-width
    border-top: 1px solid $color-gray
    border-bottom: 1px solid $color-gray
    img
      max-height: 60px
      +atMedium
        max-height: 100px

.menu-item
  position: relative

  .menu-item-submenu
    display: none
    margin-left: $sidebar-red-width

  .sidebar-item-link
    font-size: 1.2rem
    color: inherit

  .sidebar-bottom-menu &
    color: $color-primary

  &.active
    .menu-item-submenu
      display: block

    .item-label
      background-color: $color-gray

    figure img
      opacity: 1

  &.menu-item--dropdown .sidebar-item-link:after
    content: ""
    display: block
    background: url("../images/i-dropdown-chevron.svg")
    height: 10px
    width: 17px
    position: absolute
    right: 15px
    top: 0
    bottom: 0
    margin: auto
    background-size: cover

  &.menu-item--dropdown.active .sidebar-item-link:after
    transform: rotate(180deg)

  figure
    width: $sidebar-red-width
    background-color: $color-primary

    img
      opacity: 0.6
