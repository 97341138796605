// Color
.u-color
  &-primary
    color: $color-primary
  &-white
    color: $color-white
  &-dark
    color: $color-dark

// Font family
.u-font
  &-main
    &-bld
      font-family: $font-main-bld
    &-reg
      font-family: $font-main-reg

// Line height
.u-lh
  &-1-5
    line-height: 1.5

// Margin
.u-margin
  &-bottom
    &-smallest
      margin-bottom: 5px
    &-small
      margin-bottom: 1rem
      +atMedium
        margin-bottom: 10px

      +atExtralarge
        margin-bottom: .9375rem

// Alignment
.u-align
  &-inline
    &-center
      text-align: center

// Text decoration
.u-underline
  text-decoration: underline

// Position
.u-position
  &-relative
    position: relative

// Cursor
.u-cursor
  &-pointer
    cursor: pointer

// Border color
.u-border-color
  &-primary
    border-color: $color-primary

.u-border-solid-right
  border-right-width: 1px
  border-right-style: solid

.u-border-solid-top
  border-top-width: 1px
  border-top-style: solid

.min-w-0
  min-width: 0

.overflow-hidden
  overflow: hidden

.vertical-align-middle
  vertical-align: middle

.text-overflow-ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
