.media-wrapper
  margin-bottom: -1.667rem
  +atMedium
    margin-bottom: -1.25rem

  &__title
    margin-bottom: .833rem
    +atExtralarge
      margin-bottom: .9375rem
  & + &
    margin-top: 1.667rem
    +atMedium
      margin-top: 1.25rem

.media-column
  margin-bottom: 1.667rem
  +atMedium
    margin-bottom: 1.25rem
  &.media-column--fullscreen
    .media
      &__img
        width: 100vw
        height: 100vh
        padding: 0
        background-size: contain
      +fullScreenExitButtonContainer
    +fullScreen
  .media-exit
    display: none
.media
  display: block
  cursor: pointer
  &__img
    position: relative
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    display: flex
    align-items: center
    justify-content: center
    padding-bottom: 75%
    height: 0

    &-btn
      transition: all .25s
      position: absolute
      top: 0
      bottom: 0
      margin: auto

  &__info
    background-color: $color-white
    padding: 10px 1rem
    display: flex
    align-items: center
    justify-content: flex-start
    +atMedium
      padding: 10px .75rem

    &-img
      border: 1px solid $color-gray-alt-2
      border-radius: 50%
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      background-color: $color-gray-alt
      height: 3rem
      width: 3rem
      flex-shrink: 0
      +atMedium
        height: 2.25rem
        width: 2.25rem

      &--placeholder
        border-color: $color-gray-dark
        background-position: center bottom
        background-image: url("../images/avatar.svg")
    &-title
      font-size: 1.167rem
      margin-left: .833rem
      +atMedium
        font-size: .875rem
        margin-left: .625rem
  &:hover
    .media__img
      &-btn
        transform: scale(1.1)
