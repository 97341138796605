.documentation
  &__main
    margin-bottom: -1.667rem
    +atMedium
      margin-bottom: -1.25rem

    &-group
      margin-bottom: 1.667rem
      +atMedium
        margin-bottom: 1.25rem

      +atExtralarge
        margin-bottom: 1.875rem
  &__btn
    margin-top: 10px
    +atMedium
      margin-top: 6px

    +atLarge
      margin-top: .875rem



.documentation-move
  .documentation
    &__btn
      border-color: $color-move-logo
      & .btn__label
        color: $color-move-logo
      & .btn__icon
        background-color: $color-white
        border-color: $color-move-logo
      &:hover
        background-color: $color-move-logo
        .btn__label
          color: $color-white
