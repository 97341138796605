.testimonial
  background-color: $color-white
  margin-top: 2.5rem
  +atMedium
    background-color: transparent

  +atExtralarge
    margin-top: 3.75rem

  &__copy
    padding: 1.667rem
    +atMedium
      padding: 2.5rem
      background-color: $color-white

    +atExtralarge
      padding: 3.75rem
  &__img
    border-radius: 50%
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    height: 5rem
    width: 5rem
    margin: 0 auto
    +atMedium
      height: 6.25rem
      width: 6.25rem

    +atExtralarge
      height: 7.5rem
      width: 7.5rem
  &__title
    margin: 10px 0
    +atMedium
      margin: 1.25rem 0 10px 0

    +atExtralarge
      margin: 1rem 0
  &__hero
    +atMedium
      padding: 0 2.5rem

    +atExtralarge
      padding: 0 3.75rem