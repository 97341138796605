.add-cta
  display: flex
  align-items: center
  justify-content: flex-end
  cursor: pointer
  &__label
    color: $color-primary
    font-family: $font-main-bld
    font-weight: 700
    font-size: 1.167rem
    margin-right: 10px
    +atMedium
      font-size: 1.125rem

    +atHuge
      margin-right: 1.25rem
  &__btn
    background-color: $color-primary
    border-radius: 50%
    height: 2rem
    width: 2rem
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    padding: 0
    margin: 0
    transition: all .25s
    +atMedium
      height: 2.25rem
      width: 2.25rem

    +atHuge
      height: 2.625rem
      width: 2.625rem

    &-icon
      height: 40%
  &:hover
    .add-cta
      &__label
        color: $color-primary-dark
      &__btn
        background-color: $color-primary-dark
