html,
body
  overflow-x: hidden
  font-size: 12px
  position: relative
  background-color: $color-gray
  +atSmall
    font-size: 14px

  +atHuge
    font-size: 16px

body
  /* height: 100vh */
  //-ms-overflow-style: none
  &::-webkit-scrollbar
    //display: none
  &::-webkit-scrollbar-button
    //display: none

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
ul,
ol,
span
  margin: 0
  padding: 0

h1
  @extend .heading-primary

h2
  @extend .heading-secondary

li,
p
  @extend .paragraph

a
  transition: all .25s
  +linkStates($color-primary, none)
  &:hover
    color: $color-primary-dark
  &.u-color-dark
    +linkStates($color-dark, none)
    &:hover
      color: $color-primary-dark
  &.u-underline
    text-decoration: underline !important

img
  max-width: 100%
  height: auto

ul,
ol
  list-style: none
  padding-left: 0

input,
button,
select,
textarea
  border: none
  &:focus
    outline: none !important

\::selection
  background-color: $color-primary
  color: $color-white
