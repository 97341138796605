.hero
  +atHuge
    display: flex
    flex-direction: column
    height: calc(100vh - #{$topbar-height})
    max-height: 90rem
  &__main
    position: relative
    height: 20rem
    overflow: hidden
    +atSmall
      height: 15rem

    +atMedium
      height: 36rem

    +atLarge
      height: 34.125rem

    +atExtralarge
      height: 51.6875rem

    +atHuge

    &--med
      +atMedium
        height: 32.25rem

      +atLarge
        height: 30.25rem

      +atExtralarge
        height: 45.9375rem
    &--fullscreen
      .hero__slider
        .hero__slider-item
          height: 100vh
          background-size: contain
      .hero__fullscreen
        display: none
      .sitekick-hero
        display: none !important
      +fullScreen
    &--sign
      box-shadow: 0 4px 10px 0 rgba($color-black, .3)
      flex-grow: 1
      +atLarge
        box-shadow: none
  &__sign
    position: fixed
    z-index: 2
    top: 60px
    +atMedium
      top: 59px
    left: 0
    background-color: $color-primary
    text-align: center
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column!important
    .collapsed
      border-bottom: 1px solid $color-primary
      .hero__sign__btn--sold
        color: $color-white
    a
      margin: 0px
      padding: 0px
      width: 100%
    +atLarge
      bottom: auto
      left: auto
      padding: 0
      width: 17.5rem
      right: 2.5rem
    +atExtralarge
      right: 3.75rem
    &__btn
      align-items: center!important
      box-shadow: none !important
      border: 1px solid $color-primary
      font-family: "PxGrotesk-Regular","Helvetica","Arial",sans-serif
      font-size: 1.3rem
      font-weight: 400
      justify-content: flex-start
      height: 40px
      margin: 0px
      padding: 0px 15px
      &:not(.collapsed)
        border-bottom: 0px
      &:hover
        color: $color-white
      &--sold
        background-color: $color-primary
        justify-content: center
        &:hover
          color: $color-primary
    &__content
      background-color: $color-white
      border: 1px solid $color-primary
      border-top: 0px
      padding: 10px 15px 15px
      text-align: left
      width: 100%
      strong
        font-weight: 600
      &--sold
        background-color: $color-primary
        text-align: center
        h2
          color: $color-white
          font-size: 3rem
          margin-bottom: 70px
          margin-top: 70px
    &__info
      background-color: $color-primary
      border-bottom: 1px solid $color-primary
      color: $color-primary
      display: flex
      align-items: center
      justify-content: center
      height: 41px
      top: 0px
      left: -40px
      width: 40px
      +atLarge
        position: absolute
      i
        background-color: $color-white
        border-radius: 20px
        font-size: 12px
        height: 20px
        padding-top: 5px
        width: 20px
  &__overlay
    position: absolute
    z-index: 1
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 1.667rem
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-end
    +atMedium
      padding: 2.5rem
      justify-content: space-between

    +atExtralarge
      padding: 3.75rem

    &-header
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: auto
      +atMedium
        margin-bottom: 0
    &-footer
      display: flex
      align-items: center
      justify-content: flex-end
      position: absolute
      bottom: 20px
      left: 60px
      right: 20px
      +atMedium
        bottom: 60px
        right: 60px
        justify-content: space-between
  &__fullscreen
    display: inline-flex
    align-items: center
    justify-content: flex-start
    cursor: pointer
    &-icon
      margin-right: 5px
      height: 2.333rem
      width: 2.333rem
      +atMedium
        height: 1.75rem
        width: 1.75rem
    &-label
      font-family: $font-main-bld
      color: $color-white
      font-size: 1.5rem
      line-height: 1.833rem
      text-shadow: 0 2px 2px rgba($color-black, 0.8)
      +atMedium
        font-size: 1.125rem
        line-height: 1.375rem
    +fullScreenExitButtonContainer
  &__comment
    align-items: center
    cursor: pointer
    display: none
    position: absolute
    bottom: 110px
    left: 60px
    +atLarge
      bottom: 55px
      display: flex
    +atExtralarge
      bottom: 55px
    +atHuge
      bottom: 110px

    &-img
      border-radius: 50%
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      display: none
      border: 1px solid $color-gray-dark
      +atMedium
        margin-right: 10px
        display: block
        height: 2.25rem
        width: 2.25rem
    &-text
      display: flex
      align-items: center
      justify-content: center
      background-color: $color-white
      padding: 0 1.25rem
      height: 2.25rem
      border-radius: 10rem
      box-shadow: 0 4px 4px 0 rgba($color-black, 0.5)
      span
        margin-right: 5px
  &__controls
    display: flex
    align-items: center
    margin-left: auto
    justify-content: flex-end
    cursor: pointer
    &-item
      height: 1.25rem
      filter: drop-shadow(0 4px 4px rgba($color-black, 0.8))
      +atMedium
        height: 0.9375rem

      &:not(:last-child)
        margin-right: 10px
    &-playback
      display: flex
      align-items: center
      justify-content: flex-end
      &-item
        height: 1.25rem
        display: none
        +atMedium
          height: 0.9375rem

        & + &
          margin-left: 10px
        &--active
          display: block
    &-count
      line-height: 1.25rem
      font-weight: 700
      font-family: $font-main-bld
      color: $color-white
      cursor: initial
      +atMedium
        line-height: 0.9375rem
  &__slider
    &-item
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      height: 20rem
      transition: all .25s
      +atSmall
        height: 15rem

      +atMedium
        height: 36rem

      +atLarge
        height: 34.125rem

      +atExtralarge
        height: 51.6875rem

      +atHuge
        height: calc(100vh - #{$topbar-height})
        max-height: 90rem
    &--med
      .hero__slider-item
        +atMedium
          height: 32.25rem

        +atLarge
          height: 30.25rem

        +atExtralarge
          height: 45.9375rem

        +atHuge
          height: 58.125rem
  &__sub
    background-color: $color-white
    padding: 1.667rem 0
    top: 0
    &.is_stuck
      position: fixed !important
      top: 0px !important
      bottom: auto !important
      z-index: 1100
    +atMedium
      padding: 1.25rem 0
