.popup-box
    position: absolute
    bottom: 2.667rem
    right: 0
    background-color: $color-white
    width: 24.167rem
    border: 1px solid $color-gray-dark
    box-shadow: 0 2px 4px 0 rgba($color-black, .2)
    transition: all .25s
    opacity: 0
    pointer-events: none
    transform: translateY(-1rem)
    +atMedium
        width: 18.125rem
        bottom: 2rem

    &__header
        padding: 1.667rem
        border-bottom: 1px solid $color-gray-alt
        display: flex
        align-items: center
        justify-content: flex-start

    &__close
        cursor: pointer
        display: block
        margin-right: 1.667rem
        transition: all .25s
        filter: brightness(0)
        opacity: .75
        +atMedium
            margin-right: 1.25rem

        &:hover
            filter: none
            opacity: 1

    &__content
        padding: 1.167rem
        max-height: 22.9167rem
        overflow-y: auto
        +atMedium
            padding: .875rem
            max-height: 17.1875rem

    &__list
        &-item
            & + &
                margin-top: 1.667rem
                +atMedium
                    margin-top: 1.25rem

    &.active
        opacity: 1
        pointer-events: all
        transform: none
