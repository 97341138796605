.heading-primary
  font-family: $font-titles-reg
  font-weight: 400
  font-size: 2rem
  line-height: 1
  color: $color-primary
  +atSmall
    font-size: 2.25rem

  +atHuge
    font-size: 3rem

.heading-secondary
  font-family: $font-titles-reg
  font-weight: 400
  font-size: 1.333rem
  line-height: 1
  color: $color-primary
  +atSmall
    font-size: 1.125rem

  +atHuge
    font-size: 1.375rem

.paragraph
  font-family: $font-main-lgt
  font-weight: 300
  font-size: 1.167rem
  line-height: 2rem
  color: $color-dark
  +atSmall
    font-size: 1rem
    line-height: 1.625rem

  &--med
    font-size: 1rem
    line-height: 1.667rem
    +atSmall
      font-size: .875rem
      line-height: 1.25rem

.text
  & > * + *
    margin-top: 2rem
    +atMedium
      margin-top: 1.5rem