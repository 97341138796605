.modal-social
  max-width: 320px
  .social__link, .modal__link
    .fab
      vertical-align: middle
    .menu__item-arrow
      position: absolute
      top: 0
      bottom: 0
      right: 0
      margin: auto
      transform: rotate(-90deg)

    span
      font-size: 1.5rem
      vertical-align: middle
