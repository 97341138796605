.profile-box
    display: flex
    align-items: center
    justify-content: flex-start

    &__img
        height: 2.833rem
        width: 2.833rem
        border-radius: 50%
        border: 1px solid $color-gray-dark-2
        background-color: $color-gray-alt
        background-repeat: no-repeat
        background-size: cover
        background-position: center
        margin-right: 1.333rem
        +atMedium
            margin-right: 1rem
