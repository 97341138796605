.btn
  display: flex
  align-items: stretch
  justify-content: center
  padding: 0
  margin: 0
  width: 100%
  height: 3.5rem
  border-radius: 0
  background-color: $color-white
  border: 1px solid $color-primary
  border-bottom-width: 2px
  box-shadow: 0 2px 4px 0 rgba($color-black, 0.2)
  margin-top: 1.667rem
  position: relative
  transition: all .25s
  +atMedium
    align-items: center
    height: 2.625rem
    margin-top: 1.25rem

  +atExtralarge
    margin-top: 1.875rem

  &__label
    font-family: $font-main-med
    color: $color-primary
    font-size: 1.333rem
    display: flex
    align-items: center
    justify-content: center
    margin-right: 3.5rem
    transition: all .25s
    +atMedium
      font-size: 1.125rem
      margin-right: 2.625rem
  &__icon
    width: 3.5rem
    display: flex
    align-items: center
    justify-content: center
    background-color: $color-primary
    border-left: 2px solid $color-primary
    position: absolute
    top: 0
    right: 0
    height: 100%
    +atMedium
      width: 2.625rem

    &-img
      height: 25%
      &--big
        height: 40%
  &:focus
    box-shadow: none
  &:hover
    background-color: $color-primary
    .btn
      &__label
        color: $color-white

  &--more-info
    align-items: center!important
    background-color: $color-primary
    box-shadow: none !important
    color: $color-white !important
    font-size: 1.3rem
    &:hover
      background-color: $color-primary-dark

