@font-face {
  src: url("../fonts/Apercu-Light.ttf");
  font-family: "Apercu-Light";
}
@font-face {
  src: url("../fonts/Apercu-Regular.ttf");
  font-family: "Apercu-Regular";
}
@font-face {
  src: url("../fonts/Apercu-Medium.ttf");
  font-family: "Apercu-Medium";
}
@font-face {
  src: url("../fonts/Apercu-Bold.ttf");
  font-family: "Apercu-Bold";
}
@font-face {
  src: url("../fonts/PxGrotesk-Light.ttf");
  font-family: "PxGrotesk-Light";
}
@font-face {
  src: url("../fonts/PxGrotesk-Regular.ttf");
  font-family: "PxGrotesk-Regular";
}
@font-face {
  src: url("../fonts/PxGrotesk-Bold.ttf");
  font-family: "PxGrotesk-Bold";
}
html,
body {
  overflow-x: hidden;
  font-size: 12px;
  position: relative;
  background-color: #f3f3f6;
}
@media screen and (min-width: 576px) {
  html,
body {
    font-size: 14px;
  }
}
@media screen and (min-width: 1440px) {
  html,
body {
    font-size: 16px;
  }
}

body {
  /* height: 100vh */
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
ul,
ol,
span {
  margin: 0;
  padding: 0;
}

a {
  transition: all 0.25s;
}
a:link, a:hover, a:active, a:focus, a:visited {
  color: var(--landing-color-primary, #951730);
  text-decoration: none;
}
a:hover {
  color: var(--landing-color-primary-dark, #741225);
}
a.u-color-dark:link, a.u-color-dark:hover, a.u-color-dark:active, a.u-color-dark:focus, a.u-color-dark:visited {
  color: #303030;
  text-decoration: none;
}
a.u-color-dark:hover {
  color: var(--landing-color-primary-dark, #741225);
}
a.u-underline {
  text-decoration: underline !important;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

input,
button,
select,
textarea {
  border: none;
}
input:focus,
button:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

::selection {
  background-color: var(--landing-color-primary, #951730);
  color: white;
}

.heading-primary, h1 {
  font-family: "PxGrotesk-Regular", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  color: var(--landing-color-primary, #951730);
}
@media screen and (min-width: 576px) {
  .heading-primary, h1 {
    font-size: 2.25rem;
  }
}
@media screen and (min-width: 1440px) {
  .heading-primary, h1 {
    font-size: 3rem;
  }
}

.heading-secondary, h2 {
  font-family: "PxGrotesk-Regular", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.333rem;
  line-height: 1;
  color: var(--landing-color-primary, #951730);
}
@media screen and (min-width: 576px) {
  .heading-secondary, h2 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1440px) {
  .heading-secondary, h2 {
    font-size: 1.375rem;
  }
}

.paragraph, li,
p {
  font-family: "Apercu-Light", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 1.167rem;
  line-height: 2rem;
  color: #303030;
}
@media screen and (min-width: 576px) {
  .paragraph, li,
p {
    font-size: 1rem;
    line-height: 1.625rem;
  }
}
.paragraph--med {
  font-size: 1rem;
  line-height: 1.667rem;
}
@media screen and (min-width: 576px) {
  .paragraph--med {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.text > * + * {
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .text > * + * {
    margin-top: 1.5rem;
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.social__item + .social__item {
  margin-left: 1.333rem;
}
@media screen and (min-width: 576px) {
  .social__item + .social__item {
    margin-left: 0.9375rem;
  }
}

.social__link, .modal__link {
  font-size: 2.5rem;
  display: block;
}
.social__link:link, .social__link:hover, .social__link:active, .social__link:focus, .social__link:visited, .modal__link:link, .modal__link:hover, .modal__link:active, .modal__link:focus, .modal__link:visited {
  color: #303030;
  text-decoration: none;
}
@media screen and (min-width: 576px) {
  .social__link, .modal__link {
    font-size: 1.875rem;
  }
}
.social__link:hover, .modal__link:hover {
  color: var(--landing-color-primary, #951730);
}
.social__link .fa-facebook, .social__link .fa-facebook-messenger, .modal__link .fa-facebook, .modal__link .fa-facebook-messenger {
  color: #527cfc;
}
.social__link .fa-twitter-square, .modal__link .fa-twitter-square {
  color: #1e9dea;
}
.social__link .fa-whatsapp-square, .modal__link .fa-whatsapp-square {
  color: #61cb71;
}
.social__link .fa-linkedin, .modal__link .fa-linkedin {
  color: #0371ad;
}

.add-cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.add-cta__label {
  color: var(--landing-color-primary, #951730);
  font-family: "Apercu-Bold", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 1.167rem;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .add-cta__label {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1440px) {
  .add-cta__label {
    margin-right: 1.25rem;
  }
}
.add-cta__btn {
  background-color: var(--landing-color-primary, #951730);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: all 0.25s;
}
@media screen and (min-width: 768px) {
  .add-cta__btn {
    height: 2.25rem;
    width: 2.25rem;
  }
}
@media screen and (min-width: 1440px) {
  .add-cta__btn {
    height: 2.625rem;
    width: 2.625rem;
  }
}
.add-cta__btn-icon {
  height: 40%;
}
.add-cta:hover .add-cta__label {
  color: var(--landing-color-primary-dark, #741225);
}
.add-cta:hover .add-cta__btn {
  background-color: var(--landing-color-primary-dark, #741225);
}

.bottom-bar {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  transition: 0.25s all linear;
}
.menu-is-open .bottom-bar {
  bottom: -100px;
}
.bottom-bar .bottom-bar__icon {
  font-size: 20px;
}
.bottom-bar .bottom-bar__item {
  padding: 0.5rem;
  line-height: 1.2;
}
.bottom-bar .bottom-bar__item:active {
  background-color: var(--landing-color-primary, #951730);
  color: white;
  text-decoration: underline;
}
.bottom-bar .bottom-bar__item.bottom-bar__item-sitekick {
  display: none !important;
}
.sitekick-is-enabled .bottom-bar .bottom-bar__item.bottom-bar__item-sitekick {
  display: flex !important;
}
.sitekick-is-enabled .bottom-bar .bottom-bar__item.bottom-bar__item-information {
  display: none !important;
}

.testimonial {
  background-color: white;
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .testimonial {
    background-color: transparent;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial {
    margin-top: 3.75rem;
  }
}
.testimonial__copy {
  padding: 1.667rem;
}
@media screen and (min-width: 768px) {
  .testimonial__copy {
    padding: 2.5rem;
    background-color: white;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__copy {
    padding: 3.75rem;
  }
}
.testimonial__img {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 5rem;
  width: 5rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .testimonial__img {
    height: 6.25rem;
    width: 6.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__img {
    height: 7.5rem;
    width: 7.5rem;
  }
}
.testimonial__title {
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .testimonial__title {
    margin: 1.25rem 0 10px 0;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__title {
    margin: 1rem 0;
  }
}
@media screen and (min-width: 768px) {
  .testimonial__hero {
    padding: 0 2.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__hero {
    padding: 0 3.75rem;
  }
}

.btn {
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 3.5rem;
  border-radius: 0;
  background-color: white;
  border: 1px solid var(--landing-color-primary, #951730);
  border-bottom-width: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 1.667rem;
  position: relative;
  transition: all 0.25s;
}
@media screen and (min-width: 768px) {
  .btn {
    align-items: center;
    height: 2.625rem;
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .btn {
    margin-top: 1.875rem;
  }
}
.btn__label {
  font-family: "Apercu-Medium", "Helvetica", "Arial", sans-serif;
  color: var(--landing-color-primary, #951730);
  font-size: 1.333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3.5rem;
  transition: all 0.25s;
}
@media screen and (min-width: 768px) {
  .btn__label {
    font-size: 1.125rem;
    margin-right: 2.625rem;
  }
}
.btn__icon {
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--landing-color-primary, #951730);
  border-left: 2px solid var(--landing-color-primary, #951730);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .btn__icon {
    width: 2.625rem;
  }
}
.btn__icon-img {
  height: 25%;
}
.btn__icon-img--big {
  height: 40%;
}
.btn:focus {
  box-shadow: none;
}
.btn:hover {
  background-color: var(--landing-color-primary, #951730);
}
.btn:hover .btn__label {
  color: white;
}
.btn--more-info {
  align-items: center !important;
  background-color: var(--landing-color-primary, #951730);
  box-shadow: none !important;
  color: white !important;
  font-size: 1.3rem;
}
.btn--more-info:hover {
  background-color: var(--landing-color-primary-dark, #741225);
}

.characteristics {
  margin-bottom: -1.667rem;
}
@media screen and (min-width: 768px) {
  .characteristics {
    padding: 0 2.5rem;
    margin-bottom: -1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .characteristics {
    padding: 0 3.75rem;
  }
}
.characteristics__group-wrapper {
  margin-bottom: 1.667rem;
}
@media screen and (min-width: 768px) {
  .characteristics__group-wrapper {
    margin-bottom: 1.25rem;
  }
}
.characteristics__group {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.characteristics__group-header {
  padding: 1rem 1.667rem;
  border-bottom: 2px solid #f3f3f6;
}
@media screen and (min-width: 768px) {
  .characteristics__group-header {
    padding: 0.875rem 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .characteristics__group-header {
    padding: 1rem 1.25rem;
  }
}
.characteristics__group-content {
  padding: 0 1.667rem;
}
@media screen and (min-width: 768px) {
  .characteristics__group-content {
    padding: 0 1.25rem;
  }
}
.characteristics__group-feature {
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .characteristics__group-feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.characteristics__group-feature p {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .characteristics__group-feature p {
    flex: 1 0 50%;
  }
}
.characteristics__group-feature + .characteristics__group-feature {
  border-top: 2px solid #f3f3f6;
}
.characteristics__btn-wrapper {
  padding: 0 1.667rem;
}
@media screen and (min-width: 768px) {
  .characteristics__btn-wrapper {
    padding: 0;
  }
}

.documentation__main {
  margin-bottom: -1.667rem;
}
@media screen and (min-width: 768px) {
  .documentation__main {
    margin-bottom: -1.25rem;
  }
}
.documentation__main-group {
  margin-bottom: 1.667rem;
}
@media screen and (min-width: 768px) {
  .documentation__main-group {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .documentation__main-group {
    margin-bottom: 1.875rem;
  }
}
.documentation__btn {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .documentation__btn {
    margin-top: 6px;
  }
}
@media screen and (min-width: 992px) {
  .documentation__btn {
    margin-top: 0.875rem;
  }
}

.documentation-move .documentation__btn {
  border-color: #4092fe;
}
.documentation-move .documentation__btn .btn__label {
  color: #4092fe;
}
.documentation-move .documentation__btn .btn__icon {
  background-color: white;
  border-color: #4092fe;
}
.documentation-move .documentation__btn:hover {
  background-color: #4092fe;
}
.documentation-move .documentation__btn:hover .btn__label {
  color: white;
}

.features {
  margin-bottom: -1.667rem;
  margin-left: -1.667rem;
  margin-right: -1.667rem;
}
@media screen and (min-width: 768px) {
  .features {
    margin-bottom: -1.25rem;
    margin-left: 0;
    margin-right: 0;
  }
}
.features__title {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  padding: 0 1.667rem;
}
@media screen and (min-width: 1200px) {
  .features__title {
    margin-top: 3.75rem;
    margin-bottom: 1.5625rem;
    padding: 0;
  }
}
.features__item-wrapper {
  margin-bottom: 1.667rem;
}
@media screen and (min-width: 768px) {
  .features__item-wrapper {
    margin-bottom: 1.25rem;
  }
}
.features__item {
  padding: 1.667rem;
  background-color: white;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .features__item {
    padding: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .features__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
}
.features__item-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (min-width: 1200px) {
  .features__item-main {
    margin-bottom: 1.875rem;
  }
}
.features__item-media {
  height: 3.333rem;
  width: 3.333rem;
  min-height: 3.333rem;
  min-width: 3.333rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #979797;
  margin-left: 3.333rem;
  background-color: #eaeaea;
}
@media screen and (min-width: 768px) {
  .features__item-media {
    height: 3.75rem;
    width: 3.75rem;
    min-height: 3.75rem;
    min-width: 3.75rem;
    margin-left: 3.75rem;
  }
}
.features__item-media--placeholder {
  background-position: center bottom;
  background-image: url("../images/avatar.svg");
}
@media screen and (min-width: 768px) {
  .features__item-footer {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1200px) {
  .features__item-footer {
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .features__item-footer > * + * {
    margin-left: 0.5rem;
  }
}
.features__item--accent {
  border: 2px solid var(--landing-color-primary, #951730);
}
.features__item--accent .features__item-media {
  cursor: pointer;
  background-color: var(--landing-color-primary, #951730);
  border: 1px solid #cccccc;
  background-image: url("../images/i-plus.svg");
  background-repeat: no-repeat;
  background-size: auto 30%;
  background-position: center;
  transition: all 0.25s;
}
.features__item--accent .features__item-media:hover {
  background-color: var(--landing-color-primary-dark, #741225);
}

.media-wrapper {
  margin-bottom: -1.667rem;
}
@media screen and (min-width: 768px) {
  .media-wrapper {
    margin-bottom: -1.25rem;
  }
}
.media-wrapper__title {
  margin-bottom: 0.833rem;
}
@media screen and (min-width: 1200px) {
  .media-wrapper__title {
    margin-bottom: 0.9375rem;
  }
}
.media-wrapper + .media-wrapper {
  margin-top: 1.667rem;
}
@media screen and (min-width: 768px) {
  .media-wrapper + .media-wrapper {
    margin-top: 1.25rem;
  }
}

.media-column {
  margin-bottom: 1.667rem;
}
@media screen and (min-width: 768px) {
  .media-column {
    margin-bottom: 1.25rem;
  }
}
.media-column.media-column--fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  max-width: none;
  background: black;
  z-index: 2000;
}
.media-column.media-column--fullscreen .media__img {
  width: 100vw;
  height: 100vh;
  padding: 0;
  background-size: contain;
}
.media-column.media-column--fullscreen .media-exit {
  position: absolute;
  top: 0;
  right: 0;
  height: 3.75rem;
  width: 3.75rem;
  background-color: var(--landing-color-primary, #951730);
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.25s;
  display: none;
}
.media-column.media-column--fullscreen .media-exit-line {
  display: block;
  width: 1.5rem;
  height: 3px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
}
.media-column.media-column--fullscreen .media-exit-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}
.media-column.media-column--fullscreen .media-exit-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.media-column.media-column--fullscreen .media-exit:hover {
  background-color: var(--landing-color-primary-dark, #741225);
}
.media-column .media-exit {
  display: none;
}

.media {
  display: block;
  cursor: pointer;
}
.media__img {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 75%;
  height: 0;
}
.media__img-btn {
  transition: all 0.25s;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.media__info {
  background-color: white;
  padding: 10px 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .media__info {
    padding: 10px 0.75rem;
  }
}
.media__info-img {
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #eaeaea;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .media__info-img {
    height: 2.25rem;
    width: 2.25rem;
  }
}
.media__info-img--placeholder {
  border-color: #979797;
  background-position: center bottom;
  background-image: url("../images/avatar.svg");
}
.media__info-title {
  font-size: 1.167rem;
  margin-left: 0.833rem;
}
@media screen and (min-width: 768px) {
  .media__info-title {
    font-size: 0.875rem;
    margin-left: 0.625rem;
  }
}
.media:hover .media__img-btn {
  transform: scale(1.1);
}

.modal-social {
  max-width: 320px;
}
.modal-social .social__link .fab, .modal-social .modal__link .fab {
  vertical-align: middle;
}
.modal-social .social__link .menu__item-arrow, .modal-social .modal__link .menu__item-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: rotate(-90deg);
}
.modal-social .social__link span, .modal-social .modal__link span {
  font-size: 1.5rem;
  vertical-align: middle;
}

.map__main {
  height: 35.833rem;
}
@media screen and (min-width: 768px) {
  .map__main {
    height: 23.75rem;
  }
}
@media screen and (min-width: 1200px) {
  .map__main {
    height: 35.625rem;
  }
}
.map__popup {
  position: relative;
  background-color: #faf9fa;
  border: 2px solid var(--landing-color-primary, #951730);
  padding: 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 35rem;
}
@media screen and (min-width: 576px) {
  .map__popup {
    max-width: 26.25rem;
    margin-bottom: 0.75rem;
  }
}
.map__popup-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .map__popup-title {
    font-size: 1.5rem;
  }
}
.map__popup-subtitle {
  font-size: 0.875rem;
}
.map__popup-img {
  border-radius: 50%;
  border: 1px solid #cccccc;
  height: 5rem;
  width: 5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 3.333rem;
  position: relative;
}
@media screen and (min-width: 576px) {
  .map__popup-img {
    margin-left: 3.75rem;
  }
}
.map__popup-img:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  pointer-events: none;
  border-radius: 50%;
}
.map__popup:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--landing-color-primary, #951730);
  background-color: #faf9fa;
}
.map__popup:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  height: 1rem;
  width: 1.833rem;
  background-image: url("../images/popup-tip.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  transform: translateY(82%) translateX(-50%);
}
@media screen and (min-width: 768px) {
  .map__popup:after {
    height: 0.75rem;
    width: 1.375rem;
    transform: translateY(85%) translateX(-50%);
  }
}
@media screen and (min-width: 992px) {
  .map__popup:after {
    transform: translateY(75%) translateX(-50%);
  }
}
@media screen and (min-width: 1200px) {
  .map__popup:after {
    transform: translateY(82%) translateX(-50%);
  }
}

.gm-style div div:last-child div div:last-child div div:first-child div {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}
.gm-style div div:last-child div div:last-child div div:first-child + div + div {
  display: none;
}

.popup-box {
  position: absolute;
  bottom: 2.667rem;
  right: 0;
  background-color: white;
  width: 24.167rem;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.25s;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1rem);
}
@media screen and (min-width: 768px) {
  .popup-box {
    width: 18.125rem;
    bottom: 2rem;
  }
}
.popup-box__header {
  padding: 1.667rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.popup-box__close {
  cursor: pointer;
  display: block;
  margin-right: 1.667rem;
  transition: all 0.25s;
  filter: brightness(0);
  opacity: 0.75;
}
@media screen and (min-width: 768px) {
  .popup-box__close {
    margin-right: 1.25rem;
  }
}
.popup-box__close:hover {
  filter: none;
  opacity: 1;
}
.popup-box__content {
  padding: 1.167rem;
  max-height: 22.9167rem;
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .popup-box__content {
    padding: 0.875rem;
    max-height: 17.1875rem;
  }
}
.popup-box__list-item + .popup-box__list-item {
  margin-top: 1.667rem;
}
@media screen and (min-width: 768px) {
  .popup-box__list-item + .popup-box__list-item {
    margin-top: 1.25rem;
  }
}
.popup-box.active {
  opacity: 1;
  pointer-events: all;
  transform: none;
}

.profile-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profile-box__img {
  height: 2.833rem;
  width: 2.833rem;
  border-radius: 50%;
  border: 1px solid #9F9F9F;
  background-color: #eaeaea;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 1.333rem;
}
@media screen and (min-width: 768px) {
  .profile-box__img {
    margin-right: 1rem;
  }
}

#zonnestand-iframe {
  max-height: calc(100vh - 120px);
}
@media screen and (min-width: 576px) {
  #zonnestand-iframe {
    max-height: calc(100vh - 145px);
  }
}
@media screen and (min-width: 1440px) {
  #zonnestand-iframe {
    max-height: calc(100vh - 180px);
  }
}

.row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

[class^=col] {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media screen and (min-width: 1440px) {
  .hero {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    max-height: 90rem;
  }
}
.hero__main {
  position: relative;
  height: 20rem;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .hero__main {
    height: 15rem;
  }
}
@media screen and (min-width: 768px) {
  .hero__main {
    height: 36rem;
  }
}
@media screen and (min-width: 992px) {
  .hero__main {
    height: 34.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero__main {
    height: 51.6875rem;
  }
}
@media screen and (min-width: 768px) {
  .hero__main--med {
    height: 32.25rem;
  }
}
@media screen and (min-width: 992px) {
  .hero__main--med {
    height: 30.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero__main--med {
    height: 45.9375rem;
  }
}
.hero__main--fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  max-width: none;
  background: black;
  z-index: 2000;
}
.hero__main--fullscreen .hero__slider .hero__slider-item {
  height: 100vh;
  background-size: contain;
}
.hero__main--fullscreen .hero__fullscreen {
  display: none;
}
.hero__main--fullscreen .sitekick-hero {
  display: none !important;
}
.hero__main--sign {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  flex-grow: 1;
}
@media screen and (min-width: 992px) {
  .hero__main--sign {
    box-shadow: none;
  }
}
.hero__sign {
  position: fixed;
  z-index: 2;
  top: 60px;
  left: 0;
  background-color: var(--landing-color-primary, #951730);
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
}
@media screen and (min-width: 768px) {
  .hero__sign {
    top: 59px;
  }
}
.hero__sign .collapsed {
  border-bottom: 1px solid var(--landing-color-primary, #951730);
}
.hero__sign .collapsed .hero__sign__btn--sold {
  color: white;
}
.hero__sign a {
  margin: 0px;
  padding: 0px;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .hero__sign {
    bottom: auto;
    left: auto;
    padding: 0;
    width: 17.5rem;
    right: 2.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero__sign {
    right: 3.75rem;
  }
}
.hero__sign__btn {
  align-items: center !important;
  box-shadow: none !important;
  border: 1px solid var(--landing-color-primary, #951730);
  font-family: "PxGrotesk-Regular", "Helvetica", "Arial", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  justify-content: flex-start;
  height: 40px;
  margin: 0px;
  padding: 0px 15px;
}
.hero__sign__btn:not(.collapsed) {
  border-bottom: 0px;
}
.hero__sign__btn:hover {
  color: white;
}
.hero__sign__btn--sold {
  background-color: var(--landing-color-primary, #951730);
  justify-content: center;
}
.hero__sign__btn--sold:hover {
  color: var(--landing-color-primary, #951730);
}
.hero__sign__content {
  background-color: white;
  border: 1px solid var(--landing-color-primary, #951730);
  border-top: 0px;
  padding: 10px 15px 15px;
  text-align: left;
  width: 100%;
}
.hero__sign__content strong {
  font-weight: 600;
}
.hero__sign__content--sold {
  background-color: var(--landing-color-primary, #951730);
  text-align: center;
}
.hero__sign__content--sold h2 {
  color: white;
  font-size: 3rem;
  margin-bottom: 70px;
  margin-top: 70px;
}
.hero__sign__info {
  background-color: var(--landing-color-primary, #951730);
  border-bottom: 1px solid var(--landing-color-primary, #951730);
  color: var(--landing-color-primary, #951730);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  top: 0px;
  left: -40px;
  width: 40px;
}
@media screen and (min-width: 992px) {
  .hero__sign__info {
    position: absolute;
  }
}
.hero__sign__info i {
  background-color: white;
  border-radius: 20px;
  font-size: 12px;
  height: 20px;
  padding-top: 5px;
  width: 20px;
}
.hero__overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.667rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .hero__overlay {
    padding: 2.5rem;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .hero__overlay {
    padding: 3.75rem;
  }
}
.hero__overlay-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: auto;
}
@media screen and (min-width: 768px) {
  .hero__overlay-header {
    margin-bottom: 0;
  }
}
.hero__overlay-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  left: 60px;
  right: 20px;
}
@media screen and (min-width: 768px) {
  .hero__overlay-footer {
    bottom: 60px;
    right: 60px;
    justify-content: space-between;
  }
}
.hero__fullscreen {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.hero__fullscreen-icon {
  margin-right: 5px;
  height: 2.333rem;
  width: 2.333rem;
}
@media screen and (min-width: 768px) {
  .hero__fullscreen-icon {
    height: 1.75rem;
    width: 1.75rem;
  }
}
.hero__fullscreen-label {
  font-family: "Apercu-Bold", "Helvetica", "Arial", sans-serif;
  color: white;
  font-size: 1.5rem;
  line-height: 1.833rem;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 768px) {
  .hero__fullscreen-label {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}
.hero__fullscreen-exit {
  position: absolute;
  top: 0;
  right: 0;
  height: 3.75rem;
  width: 3.75rem;
  background-color: var(--landing-color-primary, #951730);
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.25s;
  display: none;
}
.hero__fullscreen-exit-line {
  display: block;
  width: 1.5rem;
  height: 3px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
}
.hero__fullscreen-exit-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}
.hero__fullscreen-exit-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.hero__fullscreen-exit:hover {
  background-color: var(--landing-color-primary-dark, #741225);
}
.hero__comment {
  align-items: center;
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 110px;
  left: 60px;
}
@media screen and (min-width: 992px) {
  .hero__comment {
    bottom: 55px;
    display: flex;
  }
}
@media screen and (min-width: 1200px) {
  .hero__comment {
    bottom: 55px;
  }
}
@media screen and (min-width: 1440px) {
  .hero__comment {
    bottom: 110px;
  }
}
.hero__comment-img {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: none;
  border: 1px solid #979797;
}
@media screen and (min-width: 768px) {
  .hero__comment-img {
    margin-right: 10px;
    display: block;
    height: 2.25rem;
    width: 2.25rem;
  }
}
.hero__comment-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 1.25rem;
  height: 2.25rem;
  border-radius: 10rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.hero__comment-text span {
  margin-right: 5px;
}
.hero__controls {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  cursor: pointer;
}
.hero__controls-item {
  height: 1.25rem;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.8));
}
@media screen and (min-width: 768px) {
  .hero__controls-item {
    height: 0.9375rem;
  }
}
.hero__controls-item:not(:last-child) {
  margin-right: 10px;
}
.hero__controls-playback {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hero__controls-playback-item {
  height: 1.25rem;
  display: none;
}
@media screen and (min-width: 768px) {
  .hero__controls-playback-item {
    height: 0.9375rem;
  }
}
.hero__controls-playback-item + .hero__controls-playback-item {
  margin-left: 10px;
}
.hero__controls-playback-item--active {
  display: block;
}
.hero__controls-count {
  line-height: 1.25rem;
  font-weight: 700;
  font-family: "Apercu-Bold", "Helvetica", "Arial", sans-serif;
  color: white;
  cursor: initial;
}
@media screen and (min-width: 768px) {
  .hero__controls-count {
    line-height: 0.9375rem;
  }
}
.hero__slider-item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 20rem;
  transition: all 0.25s;
}
@media screen and (min-width: 576px) {
  .hero__slider-item {
    height: 15rem;
  }
}
@media screen and (min-width: 768px) {
  .hero__slider-item {
    height: 36rem;
  }
}
@media screen and (min-width: 992px) {
  .hero__slider-item {
    height: 34.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero__slider-item {
    height: 51.6875rem;
  }
}
@media screen and (min-width: 1440px) {
  .hero__slider-item {
    height: calc(100vh - 60px);
    max-height: 90rem;
  }
}
@media screen and (min-width: 768px) {
  .hero__slider--med .hero__slider-item {
    height: 32.25rem;
  }
}
@media screen and (min-width: 992px) {
  .hero__slider--med .hero__slider-item {
    height: 30.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero__slider--med .hero__slider-item {
    height: 45.9375rem;
  }
}
@media screen and (min-width: 1440px) {
  .hero__slider--med .hero__slider-item {
    height: 58.125rem;
  }
}
.hero__sub {
  background-color: white;
  padding: 1.667rem 0;
  top: 0;
}
.hero__sub.is_stuck {
  position: fixed !important;
  top: 0px !important;
  bottom: auto !important;
  z-index: 1100;
}
@media screen and (min-width: 768px) {
  .hero__sub {
    padding: 1.25rem 0;
  }
}

.footer {
  padding-bottom: 8.333rem;
}
@media screen and (min-width: 768px) {
  .footer {
    padding-bottom: 8.75rem;
  }
}
@media screen and (min-width: 1200px) {
  .footer {
    padding-bottom: 11.25rem;
  }
}
.footer__logo {
  background-color: white;
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .footer__logo {
    height: 15rem;
    width: 15rem;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0.625rem;
  }
}
.footer__logo-img {
  margin: 10px;
  max-height: 9.167rem;
}
@media screen and (min-width: 768px) {
  .footer__logo-img {
    max-height: 6.875rem;
  }
}
@media screen and (min-width: 1200px) {
  .footer__logo-img {
    max-height: 7.1875rem;
  }
}
.footer__arrow {
  z-index: 1000;
  margin-top: 3.75rem;
  height: 5rem;
  width: 5rem;
  background-color: var(--landing-color-primary, #951730);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.25s;
  display: block;
  position: fixed;
  bottom: 7.5rem;
  right: 1.667rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1rem);
}
@media screen and (min-width: 768px) {
  .footer__arrow {
    bottom: 6.5rem;
    right: 2.5rem;
    height: 3.75rem;
    width: 3.75rem;
  }
}
@media screen and (min-width: 1200px) {
  .footer__arrow {
    right: 3.75rem;
    bottom: 3.75rem;
  }
}
.footer__arrow-icon {
  width: 1.667rem;
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .footer__arrow-icon {
    width: 1.25rem;
  }
}
.footer__arrow:hover {
  background-color: var(--landing-color-primary-dark, #741225);
}
.footer__arrow--active {
  transform: none;
  opacity: 1;
  pointer-events: all;
}

@media screen and (min-width: 992px) {
  .content__sidebar {
    width: 3.75rem;
  }
}
.content__main {
  transition: all 0.25s;
  margin-top: 60px;
}
.menu-is-open .content__main {
  margin-left: 300px;
}
@media screen and (min-width: 992px) {
  .content__main {
    flex: 1;
    position: relative;
    height: 100%;
  }
}
.content__row {
  padding: 0 1.667rem;
}
@media screen and (min-width: 768px) {
  .content__row {
    padding: 0 2.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .content__row {
    padding: 0 3.75rem;
  }
}

.main {
  padding-bottom: 2.5rem;
  overflow: hidden;
  transition: transform 0.25s;
  min-width: 100%;
}
@media screen and (min-width: 1200px) {
  .main {
    padding-bottom: 3.75rem;
  }
}
.main__section-headline {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .main__section-headline {
    margin-bottom: 1.25rem;
  }
}
.main__section:not(.hero) {
  margin-top: 2.5rem;
}
@media screen and (min-width: 1200px) {
  .main__section:not(.hero) {
    margin-top: 3.75rem;
  }
}

.sidebar__btn {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: transparent;
  height: 1.4167rem;
  width: 2rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .sidebar__btn {
    height: 21px;
    width: 21px;
  }
}
.sidebar__btn-line {
  height: 3px;
  width: 2rem;
  display: block;
  background-color: white;
  transition: all 0.25s;
  backface-visibility: hidden;
}
@media screen and (min-width: 768px) {
  .sidebar__btn-line {
    width: 1.5rem;
  }
}
.sidebar__btn-line + .sidebar__btn-line {
  margin-top: 4px;
}

.menu-is-open {
  overflow: hidden;
  height: 100vh;
}
@media screen and (min-width: 768px) {
  .menu-is-open {
    height: auto;
  }
}
.menu-is-open .sidebar__btn-line {
  margin-top: 0;
}
.menu-is-open .sidebar__btn-line:first-child {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.menu-is-open .sidebar__btn-line:nth-child(2) {
  opacity: 0;
}
.menu-is-open .sidebar__btn-line:last-child {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .os-windows .content__row,
.os-windows .testimonial__hero,
.os-windows .characteristics {
    padding: 0 3.6rem 0 2.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .os-windows .content__row,
.os-windows .testimonial__hero,
.os-windows .characteristics {
    padding: 0 4.75rem 0 3.75rem;
  }
}

.u-color-primary {
  color: var(--landing-color-primary, #951730);
}
.u-color-white {
  color: white;
}
.u-color-dark {
  color: #303030;
}

.u-font-main-bld {
  font-family: "Apercu-Bold", "Helvetica", "Arial", sans-serif;
}
.u-font-main-reg {
  font-family: "Apercu-Regular", "Helvetica", "Arial", sans-serif;
}

.u-lh-1-5 {
  line-height: 1.5;
}

.u-margin-bottom-smallest {
  margin-bottom: 5px;
}
.u-margin-bottom-small {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .u-margin-bottom-small {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .u-margin-bottom-small {
    margin-bottom: 0.9375rem;
  }
}

.u-align-inline-center {
  text-align: center;
}

.u-underline {
  text-decoration: underline;
}

.u-position-relative {
  position: relative;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-border-color-primary {
  border-color: var(--landing-color-primary, #951730);
}

.u-border-solid-right {
  border-right-width: 1px;
  border-right-style: solid;
}

.u-border-solid-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.min-w-0 {
  min-width: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content__sidebar {
  width: 300px;
  height: 100vh;
  background: linear-gradient(90deg, var(--landing-color-primary, #951730) 60px, white 60px);
  transition: 0.25s all linear;
  top: 0;
  left: -100vw;
  padding-top: 60px;
  z-index: 3;
}
@media screen and (min-width: 426px) {
  .content__sidebar {
    left: -300px;
  }
}
.content__sidebar:before {
  content: "";
  display: block;
  background-color: var(--landing-color-primary, #951730);
  top: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  z-index: -1;
  left: 0;
}
.menu-is-open .content__sidebar {
  left: 0;
  width: 100vw;
  overflow: auto;
}
@media screen and (min-width: 426px) {
  .menu-is-open .content__sidebar {
    width: 300px;
  }
}
.content__sidebar .logo-item {
  margin-left: 60px;
  border-top: 1px solid #f3f3f6;
  border-bottom: 1px solid #f3f3f6;
}
.content__sidebar .logo-item img {
  max-height: 60px;
}
@media screen and (min-width: 768px) {
  .content__sidebar .logo-item img {
    max-height: 100px;
  }
}

.menu-item {
  position: relative;
}
.menu-item .menu-item-submenu {
  display: none;
  margin-left: 60px;
}
.menu-item .sidebar-item-link {
  font-size: 1.2rem;
  color: inherit;
}
.sidebar-bottom-menu .menu-item {
  color: var(--landing-color-primary, #951730);
}
.menu-item.active .menu-item-submenu {
  display: block;
}
.menu-item.active .item-label {
  background-color: #f3f3f6;
}
.menu-item.active figure img {
  opacity: 1;
}
.menu-item.menu-item--dropdown .sidebar-item-link:after {
  content: "";
  display: block;
  background: url("../images/i-dropdown-chevron.svg");
  height: 10px;
  width: 17px;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: cover;
}
.menu-item.menu-item--dropdown.active .sidebar-item-link:after {
  transform: rotate(180deg);
}
.menu-item figure {
  width: 60px;
  background-color: var(--landing-color-primary, #951730);
}
.menu-item figure img {
  opacity: 0.6;
}

.sitekick-hero {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "PxGrotesk-Regular", "Helvetica", "Arial", sans-serif;
}
.sitekick-hero .sitekick-hero__title {
  max-width: 210px;
  font-size: 1.4rem;
  line-height: 1em;
}
@media screen and (min-width: 375px) {
  .sitekick-hero .sitekick-hero__title {
    font-size: 1.9rem;
  }
}
@media screen and (min-width: 768px) {
  .sitekick-hero .sitekick-hero__title {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 992px) {
  .sitekick-hero .sitekick-hero__title {
    font-size: 1.8rem;
  }
}
.sitekick-hero .sitekick-hero__separator {
  font-size: 4rem;
  width: 30px;
}
.sitekick-hero .sitekick-hero__text {
  max-width: 550px;
}
.sitekick-hero .sitekick-hero__btn {
  padding: 1rem;
  background-color: var(--landing-color-primary, #951730);
}

.topbar {
  height: 60px;
  width: 100vw;
  justify-content: space-between;
  margin-left: 0;
  transition: 0.25s all linear;
  background-color: var(--landing-color-primary, #951730);
  align-items: center;
}
.topbar .top-bar-content {
  line-height: 1.1;
}
.topbar .topbar__info-icon {
  font-size: 1.8rem;
}
.topbar .topbar__close-btn {
  transition: 0.25s all linear;
  cursor: pointer;
  flex: 0 0 60px;
  overflow: hidden;
}
.menu-is-open .topbar .topbar__close-btn {
  flex: 0 0 100vw;
  background-color: white;
  color: black;
}
.menu-is-open .topbar .topbar__close-btn .sidebar__btn-line {
  background-color: white;
  z-index: 1;
}
@media screen and (min-width: 426px) {
  .menu-is-open .topbar .topbar__close-btn {
    flex: 0 0 300px;
  }
}
.topbar .topbar__close-btn span.cl {
  white-space: nowrap;
  margin-left: 1rem;
  opacity: 0;
  transition: 0.5s opacity cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.menu-is-open .topbar .topbar__close-btn span.cl {
  opacity: 1;
}
.topbar .sidebar__btn {
  flex: 0 0 60px;
}
.topbar .sidebar__btn:before {
  content: "";
  display: block;
  background-color: var(--landing-color-primary, #951730);
  top: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  left: -300px;
  transition: 0.25s all linear;
}
.menu-is-open .topbar .sidebar__btn:before {
  left: 0;
}