.map
  &__main
    height: 35.833rem
    +atMedium
      height: 23.75rem

    +atExtralarge
      height: 35.625rem
  &__popup
    position: relative
    background-color: $color-gray-alt-3
    border: 2px solid $color-primary
    padding: 1.25rem
    margin-bottom: 1rem
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 35rem
    +atSmall
      max-width: 26.25rem
      margin-bottom: .75rem

    &-title
      font-size: 1.25rem
      margin-bottom: 10px
      +atMedium
        font-size: 1.5rem
    &-subtitle
      font-size: .875rem
    &-img
      border-radius: 50%
      border: 1px solid $color-gray-alt-2
      height: 5rem
      width: 5rem
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      margin-left: 3.333rem
      position: relative
      +atSmall
        margin-left: 3.75rem

      &:after
        content: ""
        position: absolute
        z-index: 1
        top: 0
        left: 0
        width: 100%
        height: 100%
        border: 1px solid $color-gray-alt-2
        pointer-events: none
        border-radius: 50%
    &:before
      content: ""
      position: absolute
      z-index: -1
      top: 0
      left: 0
      width: 100%
      height: 100%
      border: 2px solid $color-primary
      background-color: $color-gray-alt-3
    &:after
      content: ""
      position: absolute
      z-index: 1
      bottom: 0
      left: 50%
      height: 1rem
      width: 1.833rem
      background-image: url("../images/popup-tip.svg")
      background-repeat: no-repeat
      background-size: contain
      background-position: center top
      transform: translateY(82%) translateX(-50%)
      +atMedium
        height: .75rem
        width: 1.375rem
        transform: translateY(85%) translateX(-50%)

      +atLarge
        transform: translateY(75%) translateX(-50%)

      +atExtralarge
        transform: translateY(82%) translateX(-50%)

.gm-style div div:last-child div div:last-child div div:first-child
  div
    background-color: transparent !important
    box-shadow: none !important
    border: 0 !important
  & + div + div
    display: none