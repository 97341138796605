.bottom-bar
  left: 0
  bottom: 0
  width: 100%
  z-index: 2
  transition: $sidebar-transition
  .menu-is-open &
    bottom: -100px

  .bottom-bar__icon
    font-size: 20px

  .bottom-bar__item
    padding: 0.5rem
    line-height: 1.2
    &:active
      background-color: $color-primary
      color: white
      text-decoration: underline

    &.bottom-bar__item-sitekick
      display: none !important
      .sitekick-is-enabled &
        display: flex !important

    &.bottom-bar__item-information
      .sitekick-is-enabled &
        display: none !important
