// Colors
$color-primary: var(--landing-color-primary, #951730)
$color-primary-dark: var(--landing-color-primary-dark, #741225)

$color-dark: #303030

$color-white: white
$color-black: black

$color-gray: #f3f3f6
$color-gray-alt: #eaeaea
$color-gray-alt-2: #cccccc
$color-gray-alt-3: #faf9fa
$color-gray-dark: #979797
$color-gray-dark-2: #9F9F9F

$color-move-logo: #4092fe

$social-icon-color-facebook:  #527cfc
$social-icon-color-twitter:  #1e9dea
$social-icon-color-whatsapp:  #61cb71
$social-icon-color-linkedin:  #0371ad

$width-sidebar: 300px
$sidebar-mobile-width: 100vw
$topbar-height: 60px
$sidebar-transition: .25s all linear
$sidebar-red-width: 60px

// Typography
@font-face
  src: url("../fonts/Apercu-Light.ttf")
  font-family: "Apercu-Light"


@font-face
  src: url("../fonts/Apercu-Regular.ttf")
  font-family: "Apercu-Regular"


@font-face
  src: url("../fonts/Apercu-Medium.ttf")
  font-family: "Apercu-Medium"


@font-face
  src: url("../fonts/Apercu-Bold.ttf")
  font-family: "Apercu-Bold"


@font-face
  src: url("../fonts/PxGrotesk-Light.ttf")
  font-family: "PxGrotesk-Light"


@font-face
  src: url("../fonts/PxGrotesk-Regular.ttf")
  font-family: "PxGrotesk-Regular"


@font-face
  src: url("../fonts/PxGrotesk-Bold.ttf")
  font-family: "PxGrotesk-Bold"


$font-main-lgt: "Apercu-Light", "Helvetica", "Arial", sans-serif
$font-main-reg: "Apercu-Regular", "Helvetica", "Arial", sans-serif
$font-main-med: "Apercu-Medium", "Helvetica", "Arial", sans-serif
$font-main-bld: "Apercu-Bold", "Helvetica", "Arial", sans-serif

$font-titles-lgt: "PxGrotesk-Light", "Helvetica", "Arial", sans-serif
$font-titles-reg: "PxGrotesk-Regular", "Helvetica", "Arial", sans-serif
$font-titles-bld: "PxGrotesk-Bold", "Helvetica", "Arial", sans-serif
