.social__link, .modal__link
  font-size: 2.5rem
  +linkStates($color-dark, none)
  +atSmall
    font-size: 1.875rem

  &:hover
    color: $color-primary

  display: block
  .fa-facebook, .fa-facebook-messenger
    color: $social-icon-color-facebook

  .fa-twitter-square
    color: $social-icon-color-twitter

  .fa-whatsapp-square
    color: $social-icon-color-whatsapp

  .fa-linkedin
    color: $social-icon-color-linkedin
