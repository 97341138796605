.features
  margin-bottom: -1.667rem
  margin-left: -1.667rem
  margin-right: -1.667rem
  +atMedium
    margin-bottom: -1.25rem
    margin-left: 0
    margin-right: 0

  &__title
    margin-top: 2.5rem
    margin-bottom: 1rem
    padding: 0 1.667rem
    +atExtralarge
      margin-top: 3.75rem
      margin-bottom: 1.5625rem
      padding: 0
  &__item-wrapper
    margin-bottom: 1.667rem
    +atMedium
      margin-bottom: 1.25rem
  &__item
    padding: 1.667rem
    background-color: $color-white
    height: 100%
    +atMedium
      padding: 1.25rem

    +atExtralarge
      display: flex
      flex-direction: column
      align-items: stretch
      justify-content: space-between

    &-main
      display: flex
      align-items: flex-start
      justify-content: space-between
      +atExtralarge
        margin-bottom: 1.875rem
    &-media
      height: 3.333rem
      width: 3.333rem
      min-height: 3.333rem
      min-width: 3.333rem
      border-radius: 50%
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      border: 1px solid $color-gray-dark
      margin-left: 3.333rem
      background-color: $color-gray-alt
      +atMedium
        height: 3.75rem
        width: 3.75rem
        min-height: 3.75rem
        min-width: 3.75rem
        margin-left: 3.75rem

      &--placeholder
        background-position: center bottom
        background-image: url("../images/avatar.svg")
    &-footer
      +atMedium
        display: inline-flex
        align-items: center
        justify-content: flex-start

      +atExtralarge
        justify-content: space-between

      & > * + *
        +atMedium
          margin-left: .5rem
    &--accent
      border: 2px solid $color-primary
      .features__item
        &-media
          cursor: pointer
          background-color: $color-primary
          border: 1px solid $color-gray-alt-2
          background-image: url("../images/i-plus.svg")
          background-repeat: no-repeat
          background-size: auto 30%
          background-position: center
          transition: all .25s
          &:hover
            background-color: $color-primary-dark
