.topbar
  height: $topbar-height
  width: 100vw
  justify-content: space-between
  margin-left: 0
  transition: $sidebar-transition
  background-color: $color-primary
  align-items: center

  .top-bar-content
    line-height: 1.1

  .topbar__info-icon
    font-size: 1.8rem

  .topbar__close-btn
    transition: $sidebar-transition
    cursor: pointer
    flex: 0 0 60px
    overflow: hidden

    .menu-is-open &
      flex: 0 0 $sidebar-mobile-width
      background-color: white
      color: black

      .sidebar__btn-line
        background-color: white
        z-index: 1

    @media screen and (min-width: 426px)
      .menu-is-open &
        flex: 0 0 $width-sidebar

    span.cl
      white-space: nowrap
      margin-left: 1rem
      opacity: 0
      transition: .5s opacity cubic-bezier(0.18, 0.89, 0.32, 1.28)

      .menu-is-open &
        opacity: 1
  .sidebar__btn
    flex: 0 0 $sidebar-red-width

    &:before
      content: ""
      display: block
      background-color: $color-primary
      top: 0
      bottom: 0
      width: $sidebar-red-width
      position: absolute
      left: -$width-sidebar
      transition: $sidebar-transition
      .menu-is-open &
        left: 0
