=atExtraSmall
  @media screen and (min-width: 375px)
    @content
=atSmall
  @media screen and (min-width: 576px)
    @content

=atMedium
  @media screen and (min-width: 768px)
    @content

=atLarge
  @media screen and (min-width: 992px)
    @content

=atExtralarge
  @media screen and (min-width: 1200px)
    @content

=atHuge
  @media screen and (min-width: 1440px)
    @content

=linkStates($color, $decoration)
  &:link,
  &:hover,
  &:active,
  &:focus,
  &:visited
    color: $color
    text-decoration: $decoration

=fullScreen()
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  padding: 0
  margin: 0
  height: auto
  width: auto
  max-width: none
  background: $color-black
  z-index: 2000

=fullScreenExitButtonContainer()
  &-exit
    position: absolute
    top: 0
    right: 0
    height: 3.75rem
    width: 3.75rem
    background-color: $color-primary
    padding: 0
    margin: 0
    cursor: pointer
    transition: all 0.25s
    display: none
    &-line
      display: block
      width: 1.5rem
      height: 3px
      background-color: $color-white
      position: absolute
      top: 50%
      left: 50%
      &:first-child
        transform: translate(-50%, -50%) rotate(45deg)
      &:last-child
        transform: translate(-50%, -50%) rotate(-45deg)
    &:hover
      background-color: $color-primary-dark
