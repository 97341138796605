.main
  padding-bottom: 2.5rem
  overflow: hidden
  transition: transform .25s
  min-width: 100%
  +atExtralarge
    padding-bottom: 3.75rem

  &__section
    &-headline
      margin-bottom: 10px
      display: flex
      align-items: center
      justify-content: space-between
      +atMedium
        margin-bottom: 1.25rem
    &:not(.hero)
      margin-top: 2.5rem
      +atExtralarge
        margin-top: 3.75rem
