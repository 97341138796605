.characteristics
  margin-bottom: -1.667rem
  +atMedium
    padding: 0 2.5rem
    margin-bottom: -1.25rem

  +atExtralarge
    padding: 0 3.75rem

  &__group-wrapper
    margin-bottom: 1.667rem
    +atMedium
        margin-bottom: 1.25rem

  &__group
    background-color: $color-white
    display: flex
    flex-direction: column
    height: 100%
    &-header
      padding: 1rem 1.667rem
      border-bottom: 2px solid $color-gray
      +atMedium
        padding: .875rem 1.25rem

      +atExtralarge
        padding: 1rem 1.25rem
    &-content
      padding: 0 1.667rem
      +atMedium
        padding: 0 1.25rem
    &-feature
      padding: 10px 0
      +atMedium
        display: flex
        align-items: center
        justify-content: space-between

      p
        text-align: left
        +atMedium
          flex: 1 0 50%
      & + &
        border-top: 2px solid $color-gray
  &__btn-wrapper
    padding: 0 1.667rem
    +atMedium
      padding: 0
