.sidebar
  &__btn
    cursor: pointer
    padding: 0
    margin: 0
    background: transparent
    height: 1.4167rem
    width: 2rem
    position: relative
    +atMedium
      height: 21px
      width: 21px

    &-line
      height: 3px
      width: 2rem
      display: block
      background-color: $color-white
      transition: all .25s
      backface-visibility: hidden
      +atMedium
        width: 1.5rem

      & + &
        margin-top: 4px

.menu-is-open
  overflow: hidden
  height: 100vh

  +atMedium
    height: auto
  .sidebar
    &__btn
      &-line
        margin-top: 0
        &:first-child
          position: absolute
          top: 50%
          transform: translateY(-50%) rotate(45deg)
        &:nth-child(2)
          opacity: 0
        &:last-child
          position: absolute
          top: 50%
          transform: translateY(-50%) rotate(-45deg)
