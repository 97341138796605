.content
  &__sidebar
    +atLarge
      width: 3.75rem
  &__main
    transition: all 0.25s
    margin-top: $topbar-height

    .menu-is-open &
      margin-left: $width-sidebar
    +atLarge
      flex: 1
      position: relative
      height: 100%

  &__row
    padding: 0 1.667rem
    +atMedium
      padding: 0 2.5rem

    +atExtralarge
      padding: 0 3.75rem
