.footer
  padding-bottom: 8.333rem
  +atMedium
    padding-bottom: 8.75rem

  +atExtralarge
    padding-bottom: 11.25rem

  &__logo
    background-color: $color-white
    height: 20rem
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0 4px 10px 0 rgba($color-black, 0.3)
    margin-bottom: 2.5rem
    +atMedium
      height: 15rem
      width: 15rem
      margin: 0
      position: absolute
      bottom: 0
      right: .625rem

    &-img
      margin: 10px
      max-height: 9.167rem
      +atMedium
        max-height: 6.875rem
      +atExtralarge
        max-height: 7.1875rem
  &__arrow
    z-index: 1000
    margin-top: 3.75rem
    height: 5rem
    width: 5rem
    background-color: $color-primary
    box-shadow: 0 2px 4px 0 rgba($color-black, 0.5)
    cursor: pointer
    transition: all .25s
    display: block
    position: fixed
    bottom: 7.5rem
    right: 1.667rem
    opacity: 0
    pointer-events: none
    transform: translateY(-1rem)
    +atMedium
      bottom: 6.5rem
      right: 2.5rem
      height: 3.75rem
      width: 3.75rem

    +atExtralarge
      right: 3.75rem
      bottom: 3.75rem

    &-icon
      width: 1.667rem
      transform: rotate(180deg)
      +atMedium
        width: 1.25rem
    &:hover
      background-color: $color-primary-dark
    &--active
      transform: none
      opacity: 1
      pointer-events: all
