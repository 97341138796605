.sitekick-hero
  background-color: rgba($color-black, 0.5)
  font-family: $font-titles-reg

  .sitekick-hero__title
    max-width: 210px
    font-size: 1.4rem
    line-height: 1em

    +atExtraSmall
      font-size: 1.9rem
    +atMedium
      font-size: 1.4rem
    +atLarge
      font-size: 1.8rem

  .sitekick-hero__separator
    font-size: 4rem
    width: 30px

  .sitekick-hero__text
    max-width: 550px

  .sitekick-hero__btn
    padding: 1rem
    background-color: $color-primary
